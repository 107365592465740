<template>
  <div class="grid admintext-demo">
    <Toolbar class="toolbar_top">
      <template v-slot:start>
        <button class="p-link" id="btn_back" @click="backToRapport">
          <i class="pi pi-angle-left"></i>
        </button>
        <div class="my-2">
          <h3>{{ t('listOfTexts') }}</h3>
          <h3>{{ titleTotalNumber }}</h3>
        </div>
      </template>
      <template v-slot:end>
        <ul class="block_list">
          <li class="download_1">
            <Button icon="pi pi-download" class="button-table mr-2" mode="basic" @click="exportDocuments"
                    label="Import" chooseLabel="Import"/>
          </li>
        </ul>
      </template>
    </Toolbar>
    <div class="col-12">
      <div class="card">
        <DataTable :value="documents" :totalRecords="totalItemsDoc"
                   dataKey="index" :paginator="true" :rows="nbItems"
                   :rowsPerPageOptions="[5,10,30,50]"
                   :rowHover="true"
                   class="p-datatable-gridlines table-filter-texte"
                   v-model:filters="filters1" filterDisplay="menu" :loading="loadingDoc" :filters="filters1"
                   responsiveLayout="scroll"
                   :globalFilterFields="['titleDescription','dated_at','last_updated_at','theme_id','domain_id','sub_domain_id','is_applicable', 'rate_progress', 'rate_conformity', 'parent_site_name_4', 'parent_site_name_3', 'parent_site_name_2', 'parent_site_name_1', 'parent_site_name']"
                   @filter="filterDataTableEvent($event)"
                   @update:filters="handleFilterApplied"
        >

          <template #empty>
            {{ t('NoDataToDisplay') }}
          </template>
          <template #loading>
            {{ t('LoadingDataPleaseWait') }}
          </template>
          <Column header="" class="td_flag">
            <template #body="slotProps">
              <img :src="'demo/images/flags/flag-black.png'" :alt="slotProps.data.image" width="20"
                   v-if="slotProps?.data?.live_status?.color=='black'"/>
              <img :src="'demo/images/flags/flag-red.png'" :alt="slotProps.data.image" width="20"
                   v-if="slotProps?.data?.live_status?.color=='red'"/>
              <img :src="'demo/images/flags/flag-yellow.png'" :alt="slotProps.data.image" width="20"
                   v-if="slotProps?.data?.live_status?.color=='yellow'"/>
              <img :src="'demo/images/flags/flag-green.png'" :alt="slotProps.data.image" width="20"
                   v-if="slotProps?.data?.live_status?.color=='green'"/>
            </template>
          </Column>
          <Column field="titleDescription" :header="t('text')" style="width:10rem"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <div class="tooltip"
                   v-tooltip.right="'<p><strong>'+t('text')+ ':</strong>'+ data.title+'</p><p><strong>Description :</strong> '+
                     replaceAllColorsWithWhite(data.description)+'</p><p><strong>'+t('fieldApplication') +': </strong>'+data.application_fields+'</p>'">
                <p>{{ data.title }}</p>
                <p v-html="data.description" v-if="data.description !== 'null'" class="text-light"></p>
              </div>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                         :placeholder="t('search')"/>
            </template>
          </Column>

          <Column field="parent_site_name" header="Site" style="min-width:7rem"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              {{ data.parent_site_name }}
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                         :placeholder="t('search')"/>
            </template>
          </Column>
          <Column :header="t('dateOfText')" style="min-width:8rem" filterField="dated_at" dataType="date"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              {{ formatDate(data.dated_at) }}
            </template>
            <template #filter="{filterModel}">
              <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"
                        selectionMode="range"/>
            </template>
          </Column>
          <Column :header="t('updatedAt')" style="min-width:70px" filterField="last_updated_at"
                  :showFilterMatchModes="false">
            <template #body="{data}">
                  <span style="margin-left: .5em; vertical-align: middle"
                        class="image-text">{{ formatDate(data.last_updated_at) }}</span>
            </template>
            <template #filter="{filterModel}">
              <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"
                        selectionMode="range"/>
            </template>
          </Column>
          <Column field="theme_id" :header="t('theme')" style="min-width:8rem"
                  :showFilterMatchModes="false">
            <template #body="{data}">
                            <span style="vertical-align: middle" v-if="data.theme_id"
                                  class="image-text">{{ regexCaracters.exec(data.theme_id)[0] }} <span
                                style="display: none"> {{ regex.exec(data.theme_id)[0] }} </span></span>
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="categoriesTheme" placeholder="Any"
                        class="p-column-filter"
                        :showClear="true">
                <template #value="slotProps">
                                    <span :class="'customer-badge status-' + slotProps.value"
                                          v-if="slotProps.value">{{ regexCaracters.exec(slotProps.value)[0] }}
                                    <span style="display: none"> {{ regex.exec(slotProps.value)[0] }} </span></span>
                  <span v-else>{{ t('select') }}</span>
                </template>
                <template #option="slotProps">
                                    <span :class="'customer-badge status-' + slotProps.option.label">{{
                                        regexCaracters.exec(slotProps.option)[0]
                                      }} <span
                                          style="display: none"> {{
                                          regex.exec(slotProps.option)[0]
                                        }} </span> </span>
                </template>
              </Dropdown>
            </template>

          </Column>
          <Column field="domain_id" :header="t('domain')" :showFilterMatchModes="false"
                  :filterMenuStyle="{'width':'14rem'}" style="min-width:8rem">
            <template #body="{data}">
              <span style="vertical-align: middle" class="image-text" v-if="data.domain_id">{{
                  regexCaracters.exec(data.domain_id)[0]
                }}<span style="display: none"> {{ regex.exec(data.domain_id)[0] }} </span></span>
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="categoriesDomain" placeholder="Any"
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                                    <span :class="'customer-badge status-' + slotProps.value"
                                          v-if="slotProps.value">{{ regexCaracters.exec(slotProps.value)[0] }}
                                    <span style="display: none"> {{ regex.exec(slotProps.value)[0] }} </span></span>
                  <span v-else>{{ t('select') }}</span>
                </template>
                <template #option="slotProps">
                                    <span :class="'customer-badge status-' + slotProps.option">{{
                                        regexCaracters.exec(slotProps.option)[0]
                                      }} <span style="display: none"> {{
                                          regex.exec(slotProps.option)[0]
                                        }} </span></span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column field="sub_domain_id" :header="t('subDomain')" :showFilterMatchModes="false"
                  style="min-width:8rem">
            <template #body="{data}">
                             <span style=" vertical-align: middle" class="image-text" v-if="data.sub_domain_id">
                                   {{ regexCaracters.exec(data.sub_domain_id)[0] }}
                                 <span style="display: none"> {{ regex.exec(data.sub_domain_id)[0] }} </span>
                             </span>
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="categoriesSubDomain" placeholder="Any"
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                                  <span :class="'customer-badge status-' + slotProps.value"
                                        v-if="slotProps.value">{{ regexCaracters.exec(slotProps.value)[0] }}
                                    <span style="display: none"> {{ regex.exec(slotProps.value)[0] }} </span></span>
                  <span v-else>{{ t('select') }}</span>
                </template>
                <template #option="slotProps">
                                    <span :class="'customer-badge status-' + slotProps.option">{{
                                        regexCaracters.exec(slotProps.option)[0]
                                      }}<span style="display: none"> {{
                                          regex.exec(slotProps.option)[0]
                                        }} </span></span>
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column field="is_applicable" :header="t('applicability')" style="min-width:7rem"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <span>
                {{ data.is_applicable }}
              </span>
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="listApplicable" placeholder=" Any "
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                                                <span :class="'customer-badge status-' + slotProps.value"
                                                      v-if="slotProps.value">{{ slotProps.value }}</span>
                  <span v-else>{{ t('select') }}</span>
                </template>
                <template #option="slotProps">
                                                    <span :class="'customer-badge status-' + slotProps.option">{{
                                                        slotProps.option
                                                      }}</span>
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column field="progression" :header="t('progressRate')" filterField="rate_progress"
                  dataType="numeric"
                  style="min-width:7rem"
                  class="progressBarValue" :showFilterMatchModes="false">
            <template #body="slotProps">
              <AxProgressBar :value="Number(slotProps.data.progression).toFixed(1)"
                             v-if="slotProps.data.progression<=50 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count) && slotProps.data.applicable"
                             :showValue="true" style="height:20px" class="valuered"></AxProgressBar>
              <AxProgressBar :value="Number(slotProps.data.progression).toFixed(1)"
                             v-if="slotProps.data.progression>50 && slotProps.data.progression<70 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count) && slotProps.data.applicable"
                             :showValue="true"
                             style="height:20px" class="valueyellow"></AxProgressBar>
              <AxProgressBar :value="Number(slotProps.data.progression).toFixed(1)"
                             v-if="slotProps.data.progression>=70 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count) && slotProps.data.applicable"
                             :showValue="true" style="height:20px" class="valuegreen"></AxProgressBar>
            </template>
            <!--            <template #filter={filterModel}>-->
            <!--              <Slider v-model="filterModel.value" range class="m-3"></Slider>-->
            <!--              <div class="flex align-items-center justify-content-between px-2">-->
            <!--                <span>{{ filterModel.value ? filterModel.value[0] : 0 }}</span>-->
            <!--                <span>{{ filterModel.value ? filterModel.value[1] : 100 }}</span>-->
            <!--              </div>-->
            <!--            </template>-->
          </Column>
          <Column field="conformity" :header="t('complianceRate')" filterField="rate_conformity"
                  dataType="numeric"
                  style="min-width:7rem" :showFilterMatchModes="false">
            <template #body="slotProps">
              <AxProgressBar :value="Number(slotProps.data.conformity).toFixed(1)"
                             v-if="slotProps.data.conformity<=50 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count) && slotProps.data.applicable"
                             :showValue="true" style="height:20px" class="valuered"></AxProgressBar>
              <AxProgressBar :value="Number(slotProps.data.conformity).toFixed(1)"
                             v-if="slotProps.data.conformity>50 && slotProps.data.conformity<70 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count) && slotProps.data.applicable"
                             :showValue="true"
                             style="height:20px" class="valueyellow"></AxProgressBar>
              <AxProgressBar :value="Number(slotProps.data.conformity).toFixed(1)"
                             v-if="slotProps.data.conformity>=70 && !verifPiNa(slotProps.data.compliance_na_count, slotProps.data.compliance_pi_count, slotProps.data.compliances_count) && slotProps.data.applicable"
                             :showValue="true" style="height:20px" class="valuegreen"></AxProgressBar>
            </template>
            <!--            <template #filter={filterModel}>-->
            <!--              <Slider v-model="filterModel.value" range class="m-3"></Slider>-->
            <!--              <div class="flex align-items-center justify-content-between px-2">-->
            <!--                <span>{{ filterModel.value ? filterModel.value[0] : 0 }}</span>-->
            <!--                <span>{{ filterModel.value ? filterModel.value[1] : 100 }}</span>-->
            <!--              </div>-->
            <!--            </template>-->
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <Toast/>
</template>

<script>
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {FilterMatchMode} from "primevue/api";
import {computed} from "vue";
import {useToast} from "primevue/usetoast";
import {usePrimeVue} from "primevue/config";
import AxProgressBar from "@/components/AxProgressBar";
import {useRoute} from "vue-router";
import moment from "moment-timezone";

export default {
  components: {
    AxProgressBar
  },
  data() {
    return {
      documents: [],
      filters1: null,
      loadingDoc: true,
      totalItemsDoc: 0,
      nbItems: 10,
      nbPageDocument: 1,
      categoriesTheme: [],
      categoriesDomain: [],
      categoriesSubDomain: [],
      categoriesThemeDocument: [],
      categoriesDomainDocument: [],
      categoriesSubDomainDocument: [],
      textAdmin: '',
      categoryValueTheme: '',
      categoryValueDomain: '',
      categoryValueSubDomain: '',
      tabCategoriesTheme: [],
      tabCategoriesDomain: [],
      tabCategoriesSubDomain: [],
      verifAdmin: true,
      verifSuperAdmin: false,
      categoriesThemeConst: [],
      categoriesDomainConst: [],
      categoriesSubDomainConst: [],
      applicabilities: [{value: 1, label: 'Pour information'}, {value: 2, label: 'Applicable'}],
      is_applicable: '',
      rateProgressEvalFilter: '',
      rateConformityEvalFilter: '',
      listSiteByEntity: [],
      listSites: [],
      parent_site_name_4: '',
      regex: /\d+$/,
      regexCaracters: /^(.*?)(?=\d)/,
      listApplicable: [],
      titleTotalNumber: '',
    }
  },
  setup() {
    const id_site = computed(() => {
      return store.getters.currentSite.id
    });
    const site = computed(() => {
      return store.getters.currentSite
    });
    const primevue = usePrimeVue();
    const store = useStore();
    const route = useRoute();
    const toast = useToast();
    const user = computed(() => {
      return store.getters.currentUser
    });
    const list_site = computed(() => {
      return store.getters.getListSites;
    })
    const is_rte = computed(() => {
      return store.getters.isRte
    });
    return {
      id_site,
      store,
      route,
      toast,
      primevue,
      t: primevue.config.StoreTranslation.t,
      user,
      list_site,
      site,
      is_rte
    }
  },
  async created() {
    this.initFilters1();
  },

  async mounted() {
    this.startProgress();
    if (this.user?.application_master) {
      this.verifSuperAdmin = true
    }
    //  await this.getCurrentSites()
    this.listSites = this.list_site
    await this.getCategoriesByTheme();
    if (this.list_site)
      await this.getDocuments(1, this.nbItems);
  },
  beforeUnmount() {
    this.endProgress();
  },
  watch: {
    id_site() {
      this.getDocuments(1, this.nbItems);
    },
    user() {
      if (this.user?.role_user && this.user.role_user[0] !== 'ROLE_ADMIN') {
        this.verifAdmin = false
      }
      if (this.user?.application_master) {
        this.verifSuperAdmin = true
      }
      this.user_id = this.user?.id
    },

    list_site(value) {
      if (value) {
        this.listSites = this.list_site
        this.getDocuments(1, this.nbItems);
      }
    }
  },
  methods: {
    startProgress() {
    },
    endProgress() {

    },
    initFilters1() {
      this.filters1 = {
        'titleDescription': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'dated_at': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'last_updated_at': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'theme_id': {value: null, matchMode: FilterMatchMode.EQUALS},
        'domain_id': {value: null, matchMode: FilterMatchMode.EQUALS},
        'sub_domain_id': {value: null, matchMode: FilterMatchMode.EQUALS},
        'is_applicable': {value: null, matchMode: FilterMatchMode.EQUALS},
        'rate_progress': {value: null, matchMode: FilterMatchMode.EQUALS},
        'rate_conformity': {value: null, matchMode: FilterMatchMode.EQUALS},
        'parent_site_name_4': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'parent_site_name_3': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'parent_site_name_2': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'parent_site_name_1': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'parent_site_name': {value: null, matchMode: FilterMatchMode.CONTAINS},

      }
    },
    clearFilter1() {
      this.initFilters1();
    },
    getDocuments(page = 1, nbItems) {
      this.loadingDoc = true;
      this.documents = []
      this.nbItems = nbItems
      let idTheme = ''
      let idDomain = ''
      let idSubDomain = ''
      if (this.categoryValueTheme && !this.categoryValueSubDomain) {
        idTheme = this.categoryValueTheme.id
      }
      if (this.categoryValueDomain) {
        idDomain = this.categoryValueDomain.id
      }
      if (this.categoryValueSubDomain) {
        idSubDomain = this.categoryValueSubDomain.id
      }
      this.categoriesThemeDocument = []
      this.categoriesDomainDocument = []
      this.categoriesSubDomainDocument = []
      let sites = this.listSites.length > 0 ? this.listSites : [this.id_site]
      return this.store.dispatch(Actions.AFFECTATIONS_APPLICABLE, {
        'idSite': sites,
        'page': page,
        'limit': this.nbItems,
        'theme': idTheme,
        'domain': idDomain,
        'sub_domain': idSubDomain,
        'text': this.textAdmin,
        'customHeader': true,
        is_applicable: this.is_applicable,
        'rate_progress': this.rateProgressEvalFilter,
        'rate_conformity': this.rateConformityEvalFilter,
        'parent_site_name_4': this.parent_site_name_4
      }).then(data => {
        if (data) {
          this.expandedRowsDoc = null;
          this.totalItemsDoc = data['result'].length
          data['result'].forEach((element, index) => {
            element.dated_at = element.dated_at ? new Date(element['dated_at']) : ''
            element.last_updated_at = element.last_updated_at ? new Date(element['last_updated_at']) : ''

            element['titleDescription'] = element['title'] + '' + element['description'] + '' + element.document?.application_fields
            element['index'] = index
            // if (element['parent_site_name'])
            element['parent_site_name'] = element['parent_site_name'] ? element['parent_site_name'] + '-' + element['site_name'] : element['site_name']
            // else
            //     element['parent_site_name'] = element['site_name']
            let objTheme = this.categoriesThemeConst.find(o => o.id == element.theme_id);
            let objDomain = this.categoriesDomainConst.find(o => o.id == element.domain_id);
            let objSubDomain = this.categoriesSubDomainConst.find(o => o.id == element.sub_domain_id);
            this.categoriesThemeDocument.push(element.theme_id)
            this.categoriesDomainDocument.push(element.domain_id)
            this.categoriesSubDomainDocument.push(element.sub_domain_id)
            if (objTheme) {
              element['theme_id'] = objTheme.name + ' ' + objTheme.id
            } else {
              element['theme_id'] = objTheme
            }
            if (objDomain) {
              element['domain_id'] = objDomain.name + ' ' + objDomain.id

            } else {
              element['domain_id'] = objDomain
            }
            if (objSubDomain) {
              element['sub_domain_id'] = objSubDomain.name + ' ' + objSubDomain.id

            } else {
              element['sub_domain_id'] = objSubDomain
            }
            if (element.is_applicable) {
              element.is_applicable = 'Avec exigences'
              element.applicable = 1
            } else {
              element.is_applicable = 'Pour information'
              element.applicable = 0
            }
            if (this.listApplicable.indexOf(element.is_applicable) === -1) {
              this.listApplicable.push(element.is_applicable)
            }

          })
          this.documents = data['result']
          this.loadingDoc = false;
        }
      }).catch(() => {
      })
    },
    onPageDoc(event) {
      this.nbItems = event.rows
      this.nbPageDocument = event.page + 1
      this.getDocuments(event.page + 1, this.nbItems);
    },
    getCategoriesByTheme() {
      let tabCategoriesTheme = []
      let tabCategoriesDomain = []
      let tabCategoriesSubDomain = []
      return this.store.dispatch(Actions.GET_CATEGORIES_TREE_FILTER, {
        site: this.id_site,
        'is_applicable': 'all',
        'customHeader': false,
      }).then(data => {
        var formatSelectTree = (obj) => {
          obj.forEach((element) => {
            if (Object.prototype.hasOwnProperty.call(element, 'categories')) {
              formatSelectTree(element.categories)
              if (element?.type === 'THEME') {
                tabCategoriesTheme.push(element)
              } else if (element?.type === 'DOMAIN') {
                tabCategoriesDomain.push(element)
              }
            } else {
              if (element?.type === 'SUB_DOMAIN') {
                tabCategoriesSubDomain.push(element)
              }
            }
            Object.assign(element, {"children": element.categories})
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"label": element.name})
            Object.assign(element, {"key": element.id})
            Object.assign(element, {
              "data": {
                "id": element.id,
                "name": element.name,
                "type": element.type,
                "code": element.code,
              }
            })
          });
          return obj;
        };
        this.tabCategories = formatSelectTree(data)
        // this.categoriesTheme = tabCategoriesTheme;
        tabCategoriesTheme.sort((a, b) => a.name?.localeCompare(b.name))
        tabCategoriesDomain.sort((a, b) => a.name?.localeCompare(b.name))
        tabCategoriesSubDomain.sort((a, b) => a.name?.localeCompare(b.name))
        this.categoriesTheme = tabCategoriesTheme.map((obj) => obj.name + ' ' + obj.id)
        this.categoriesDomain = tabCategoriesDomain.map((obj) => obj.name + ' ' + obj.id);
        this.categoriesSubDomain = tabCategoriesSubDomain.map((obj) => obj.name + ' ' + obj.id);

        this.tabCategoriesTheme = tabCategoriesTheme
        this.tabCategoriesDomain = tabCategoriesDomain
        this.tabCategoriesSubDomain = tabCategoriesSubDomain

        this.categoriesThemeConst = tabCategoriesTheme
        this.categoriesDomainConst = tabCategoriesDomain
        this.categoriesSubDomainConst = tabCategoriesSubDomain
      })
    },
    filterDataTable(event) {
      console.log(event, 'eventtt')
      this.textAdmin = event?.filters?.titleDescription?.value
      this.is_applicable = event?.filters?.is_applicable?.value?.value
      this.categoryValueTheme = event?.filters?.theme?.value
      this.rateProgressEvalFilter = event?.filters?.rate_progress?.value
      this.rateConformityEvalFilter = event?.filters?.rate_conformity?.value
      this.parent_site_name_4 = event?.filters?.parent_site_name_4?.value
      if (!event?.filters?.theme?.value && !event?.filters?.domain?.value && !event?.filters?.subDomain?.value) {
        this.categoriesTheme = this.tabCategoriesTheme
        this.categoriesDomain = this.tabCategoriesDomain
        this.categoriesSubDomain = this.tabCategoriesSubDomain
      }
      if (event?.filters?.theme?.value) {
        this.changeTheme()
      } else if (!event?.filters?.domain?.value) {
        this.getCategoriesByTheme()
      }
      if (this.categoriesDomain.length > 0) {
        if (event?.filters?.domain?.value !== this.categoryValueDomain) {
          this.categoryValueDomain = event.filters.domain.value
          this.changeDomain()
          if ((this.categoryValueSubDomain === 2 && !event?.filters?.subDomain?.value) || (this.categoryValueSubDomain === 2 && event?.filters?.subDomain?.value)) {
            event.filters.subDomain.value = null
          }
        }
        this.categoryValueSubDomain = event?.filters?.subDomain?.value
      } else {
        event.filters.domain.value = null
        event.filters.subDomain.value = null
        this.categoryValueDomain = ''
        this.categoryValueSubDomain = null
      }

      this.getDocuments(this.nbPageDocument, this.nbItems)
    },
    changeTheme() {
      let tabCategoriesDomain = []
      let tabCategoriesSubDomain = []
      let objTheme = this.categoriesTheme.find(o => o.id == this.categoryValueTheme.id);
      if (objTheme) {
        objTheme.categories?.forEach(function (element) {
          if (element.type === 'DOMAIN') {
            tabCategoriesDomain?.push(element)
            if (element.categories?.length > 0) {
              element.categories?.forEach(elemSubDomain => {
                tabCategoriesSubDomain?.push(elemSubDomain)
              })
            }
          }
        })
      }
      this.categoriesDomain = tabCategoriesDomain;
      this.categoriesSubDomain = tabCategoriesSubDomain;
    },
    changeDomain() {
      let tabCategoriesSubDomain = []
      if (this.categoryValueDomain) {
        let objDomain = this.categoriesDomain.find(o => o.id == this.categoryValueDomain.id);
        objDomain.categories.forEach(function (element) {
          if (element.type === 'SUB_DOMAIN') {
            tabCategoriesSubDomain.push(element)
          }
        })
        this.categoryValueSubDomain = 2
        this.categoriesSubDomain = tabCategoriesSubDomain;
      }
    },
    truncate(source) {
      if (source) {
        let size
        if (screen.width <= 768) {
          size = 100
          return source.length > size ? source.slice(0, size - 1) + " …" : source;
        } else {
          size = 125
          return source.length > size ? source.slice(0, size - 1) + " …" : source;
        }
      }
    },
    truncateText(source) {
      if (source) {
        let size
        size = 60
        return source.length > size ? source.slice(0, size - 1) + " …" : source;
      }
    },
    verifPiNa(NA, PI, nbCompliance) {
      if (nbCompliance)
        return parseInt(NA) + parseInt(PI) === nbCompliance
      else return true
    },
    formatDate(value) {
      if (value) {
        return new Date(value).toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      } else return ' '
    },
    backToRapport() {
      this.$router.push('/rapport');
    },
    exportDocuments() {
      let user = '/api/users/' + this.user.id

      let sites = this.listSites.length > 0 ? this.listSites : [this.id_site]
      const regex = /\d+$/;
      let id_them = this.filters1?.theme_id?.value;
      const theme_id = regex.exec(id_them) ? regex.exec(id_them)[0] : '';

      let id_domain = this.filters1?.domain_id?.value;
      const idDomain = regex.exec(id_domain) ? regex.exec(id_domain)[0] : '';

      let id_sub_domain = this.filters1?.sub_domain_id?.value;
      const idSubDomain = regex.exec(id_sub_domain) ? regex.exec(id_sub_domain)[0] : '';

      let dated_at_start = null
      let dated_at_end = null

      console.log(this.filters1?.dated_at?.value, 'tetsts')
      if (this.filters1?.dated_at?.value) {
        dated_at_start = this.addStartOfDayTime(this.filters1?.dated_at?.value[0])
        dated_at_end = this.filters1?.dated_at?.value[1] ?? this.addEndOfDayTime(moment(this.filters1?.dated_at?.value[0]).format('yy-MM-DD'))
      }
      console.log(dated_at_start, 'dated_at_start')
      console.log(dated_at_end, 'dated_at_end')

      let last_updated_at_start = null
      let last_updated_at_end = null

      if (this.filters1?.last_updated_at?.value) {
        last_updated_at_start = this.filters1?.last_updated_at?.value[0]
        last_updated_at_end = this.filters1?.last_updated_at?.value[1] ?? this.addEndOfDayTime(moment(this.filters1?.last_updated_at?.value[0]).format('yy-MM-DD'))
      }

      this.store.dispatch(Actions.EXPORTS_POST, {
        user: user,
        model: "applicableTextExport",
        params: {
          site_id: this.id_site,
          theme_id: theme_id,
          domain_id: idDomain,
          sub_domain_id: idSubDomain,
          text_content: this.filters1?.titleDescription?.value,
          is_applicable: this.filters1?.is_applicable?.value,
          //  rate_progress: this.rateProgressEvalFilter,
          //   rate_conformity: this.rateConformityEvalFilter,
          parent_site_name_4: this.filters1?.parent_site_name_4?.value,
          parent_site_name_3: this.filters1?.parent_site_name_3?.value,
          parent_site_name_2: this.filters1?.parent_site_name_2?.value,
          parent_site_name_1: this.filters1?.parent_site_name_1?.value,
          parent_site_name: this.filters1?.parent_site_name?.value,
          sites: sites,
          type_site: this.site.type,
          dated_at_start: dated_at_start,
          dated_at_end: dated_at_end,
          last_updated_at_start: last_updated_at_start,
          last_updated_at_end: last_updated_at_end,
        },
        site_id: parseInt(this.id_site),
      }).then(() => {
        this.toast.add({
          severity: 'success',
          detail: this.t('exportWasSuccessfullySent'),
          life: 8000
        });
      })
    },

    getCurrentSites() {
      this.listSiteByEntity = []
      let exist = false
      let objectCurrentSite = []
      this.treeSelectNodes = []
      return this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {'customHeader': false}).then(data => {
        this.listSitesValid = true
        var formatSelectTree = (obj) => {
          obj?.forEach((element) => {
            if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
              formatSelectTree(element.childrens)
            }
            Object.assign(element, {"children": element.childrens})
            if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
              Object.assign(element, {"label": element.site_name})
              element.id = element.site_id
            } else {
              Object.assign(element, {"label": element.name})
            }
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"key": element.id})
          });
          return obj;
        };
        this.treeSelectNodes = formatSelectTree(data);

        function getObjectCurrentSite(arr, id_site) {
          arr?.forEach((obj) => {
            if (obj.childrens && !exist) {
              getObjectCurrentSite(obj.childrens, id_site, obj);
            }
            if (obj.childrens && obj.id == id_site) {
              exist = true
              objectCurrentSite = obj
              return objectCurrentSite
            }

          });
          return objectCurrentSite
        }

        objectCurrentSite = getObjectCurrentSite(this.treeSelectNodes, this.id_site)
        const getSitesTree = (objectCurrentSite) => {
          if (objectCurrentSite.childrens) {
            objectCurrentSite.childrens.forEach(element => {
              if (element.entity_type === 'site') {
                this.listSiteByEntity.push(element.id);
              } else {
                getSitesTree(element);
              }
            });
          }
        };
        getSitesTree(objectCurrentSite);
      })
    },
    filterDataTableEvent(event) {
      this.titleTotalNumber = event.filteredValue?.length > 1 ? event.filteredValue?.length + ' ' + this.t('textes') : event.filteredValue?.length + ' ' + this.t('texte');
    },
    replaceAllColorsWithWhite(html) {
      if (!html) return '';
      return html.replace(/color: rgb\(\d{1,3}, \d{1,3}, \d{1,3}\);/g, 'color: white;');
    },
    handleFilterApplied(event) {
      if (event.dated_at?.value) {
        if (!event.dated_at?.value[1]) {
          event.dated_at.value = [
            event.dated_at?.value[0],
            this.addEndOfDayTime(moment(event.dated_at?.value[0]).format('yy-MM-DD'))
          ];

        }
      }
      if (event.last_updated_at?.value) {
        if (!event.last_updated_at?.value[1]) {
          event.last_updated_at.value = [
            event.last_updated_at?.value[0],
            this.addEndOfDayTime(moment(event.last_updated_at?.value[0]).format('yy-MM-DD'))
          ];

        }
      }
    },
    addEndOfDayTime(dateString) {
      const endDate = new Date(dateString);
      // Réglage de l'heure à 23:59:59
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      return endDate;

    },
    addStartOfDayTime(dateString) {
      const startDate = new Date(dateString);
      // Réglage de l'heure à 00:00:00
      startDate.setHours(0, 0, 0, 0);
      return startDate;

    },
  }
}
</script>

<style scoped>

</style>